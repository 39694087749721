const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selected: null,
  middlewares: {},
  logs: [],
  response: null,
  usage: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_RESPONSE':
      return { 
        ...state,
        response: null 
      }        
    case 'GET_STYLESHEET_DATA':
      return {
        ...state,
        allData: action.allData,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'NEW_STYLESHEET':
      return { 
        ...state,
        middlewares: action.middlewares,
      }    
    case 'CREATE_STYLESHEET':
      return { 
        ...state,
        response: action.response 
      }            
    case 'EDIT_STYLESHEET':
      return { 
        ...state, 
        selected: action.selected,
        middlewares: action.middlewares,
      }
    case 'UPDATE_STYLESHEET':
      return { 
        ...state,
        response: action.response 
      }    
    case 'GET_STYLESHEET_LOGS':
      return { 
        ...state, 
        logs: action.logs,
        total: action.totalPages,
        params: action.params        
      }   
    case 'GET_STYLESHEET_USAGE':
      return { 
        ...state, 
        usage: action.usage,
        total: action.totalPages,
        params: action.params        
      }            
    case 'DELETE_STYLESHEET':
      return { 
        ...state,  
        response: action.response
      }
    default:
      return { ...state }
  }
}
export default reducer