// ** React Imports
import { useState, createContext, useEffect } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'
import moment from 'moment'
import 'moment/locale/fi'

// ** Core Language Data
import messagesEn from '@assets/data/locales/en.json'
import messagesFi from '@assets/data/locales/fi.json'

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, },
  fi: { ...messagesFi, },
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  // ** States

  const $locale = localStorage.getItem("locale");

  const [locale, setLocale] = useState($locale || 'fi')
  const [messages, setMessages] = useState(menuMessages[$locale || 'fi'])

  // console.log(moment.locale())
  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    localStorage.setItem('locale', lang)
    setMessages(menuMessages[lang])
    moment.locale(lang)
  }

  useEffect(() => {
    moment.locale($locale)
  }, [])

  // useEffect(() => {
  //   const $locale = localStorage.getItem("locale")
  //   switchLanguage(localStorage.getItem("locale") || "fi")

  //   // if (!$locale){
  //   //   localStorage.setItem('locale', "fi")
  //   //   setLocale("fi")
  //   // } else {
  //   //   setLocale($locale)      
  //   // }
  // }, [])

  return (
    <Context.Provider value={{ locale, switchLanguage, }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='fi'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
