const initialState = {
  data: {
    products: [],
    exhausted: false,
    campaign: null,    
  },
  params: {},
  selected: null,
  middlewares: {},
  response: null,
  page: 0
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_RESPONSE':
      return { 
        ...state,
        response: null 
      }        
    case 'GET_BOOKING_DATA':
      return {
        ...state,
        data: {
          products: action.data.page === 0 ? action.data.products : [...state.data.products, ...action.data.products],
          exhausted: action.data.products?.length !== 8,
          campaign: action.campaign,
        },
        params: action.params,
        page: action.data.page,
      }
    case 'CREATE_BOOKING':
      return { 
        ...state,
        response: action.response,
        payment: action.payment,
        order: action.order,
        selected: action.selected,   
        concludedOrder: action.concludedOrder, 
      }
    case 'CONCLUDE_BOOKING':
      return { 
        ...state,
        response: action.response,
        concludedOrder: action.concludedOrder,
      }      
    default:
      return { ...state }
  }
}
export default reducer




